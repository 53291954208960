//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'
/*
    public int? Id { get; set; }
        public int? IdImpuesto { get; set; }
        public int? IdTipoTasa { get; set; }
        public string? Descripcion { get; set; }
        public double? Valor { get; set; }
        public string? Abreviatura { get; set; }
        public bool? IndActivo { get; set; } */
class TasaImpuestoServices {
     Consultar(
        datos = {
            Id: -1,
            IdImpuesto: -1,
            IdTipoTasa: -1,
            Descripcion: '',
            Valor:"",
            Abreviatura:"",
            IndActivo:null
        }    
     ) {
        return http.get("tasaimpuesto/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 

     ConsultarSelect(
        datos = {
            Id: -1,
            IdImpuesto: -1,
            IdTipoTasa: -1,
            Descripcion: '',
            Valor:"",
            Abreviatura:"",
            IndActivo:null
        }
    ) {
        return http.get("tasaimpuesto/consultar/select", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 
}


export default new TasaImpuestoServices();