<template>
  <v-card   :width="_width">
    <span>{{ title }}</span> 
    <v-carousel :height="_height" :show-arrows="imagenes.length > 1" hide-delimiters>
      <v-carousel-item v-for="(item, id) in imagenes" :key="id">
        <v-img :src="`${config.api_imagenes}${item.formats.small.ruta}`" :height="_height" contain>
          <v-card-text>
            <v-btn icon class="white mr-2"  @click="Abrir()">
              <v-tooltip activator="parent" location="top">Agregar imagen</v-tooltip>
              <v-icon color="primary">
                {{ icons.mdiPlusBoxOutline }}
              </v-icon>
            </v-btn>
            <v-btn icon class="white" @click="eliminarImg(item)">
              <v-icon color="error">
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-btn>
          </v-card-text>
        </v-img>
      </v-carousel-item>
      <v-carousel-item v-if="imagenes.length == 0">
        <v-card @click="Abrir()" dark primary height="100%">
          <v-row class="fill-height" align="center" justify="center">
            <div class="text-h7">Seleccione una imagen</div>
          </v-row>
        </v-card>
      </v-carousel-item>
    </v-carousel> 
    <Modal ref="ModalUpImgRef" :_multiSelected="_multiSelected" :_selected="_selected" @sendImgSelect="sendImgSelect" />
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import Modal from './Modal.vue'
import { mdiPlusBoxOutline, mdiDeleteOutline } from '@mdi/js'
import config from '@/api/config'
export default {
  props: {
    title: String,
    _multiSelected: Boolean,
    _selected: Boolean,
    _width:{
      type:Number,
      default:344
    },
    _height:{
      type:Number,
      default:200
    },
    _objeto:Object,
    value:Object
  },
  components: {
    Modal,
  },
  setup(props, context) {
    const imagenes = ref([])
    const ModalUpImgRef = ref(null)
    const Abrir = () => {
      ModalUpImgRef.value.Abrir(imagenes.value)
    }
    const eliminarImg = item => {
      imagenes.value = imagenes.value.filter(it => item.id != it.id)
      context.emit('sendImg', {img:imagenes.value, objeto: props._objeto})
    }
    const sendImgSelect = items => {
      console.log(items)
      imagenes.value = items
      ModalUpImgRef.value.dialog = false 
      
      context.emit('sendImg', {img:items, objeto: props._objeto})
    }
    onBeforeMount(()=>{
      if (props.value &&  props.value.length > 0) { 
       
        console.log("Trae imagen",props.value)
        imagenes.value = props.value
      }
    })

    return {
      ModalUpImgRef,
      Abrir,
      imagenes,
      sendImgSelect,
      config,
      eliminarImg,
      icons: {
        mdiPlusBoxOutline,
        mdiDeleteOutline,
      },
    }
  },
}
</script>