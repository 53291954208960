//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class ProductoSucursalServices {

    ActualizarMasivo(
        idUsuario,
        Data
    ) {
        const datos = {
            idUsuario,
            Data
        }
        console.log(datos)
        return http.post("/productoSucursal/actualizar/masivo", datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    ConsultarBuscar(
        valor,
        idSucursal
    ) {
        return http.get("ProductoSucursal/consultar/buscar", { params: { valor, idSucursal } })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
    ConsultarBarra(
        valor,
        idSucursal,
        idArea  = -1,
        indInventario = 1
    ) {
        return http.get("ProductoSucursal/consultar/barra", { params: { valor, idSucursal , idArea,indInventario} })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    ConsultarAvanzada(
        datos
    ) {
        return http.get("ProductoSucursal/consultar/avanzada", { params: { ...datos } })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

}


export default new ProductoSucursalServices();