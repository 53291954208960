//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class GrupoUnidadMedidaServices {
     Consultar(
        datos = {
            Id: -1,
            Nombre: "",
            Abreviatura: "",
            IndActivo: null,
        }    
     ) {
        return http.get("grupounidadmedida/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 

     ConsultarSelect(
        datos = {
            Id: -1,
            Nombre: "",
            Abreviatura: "",
            IndActivo: null,
        }
    ) {
        return http.get("grupounidadmedida/consultar/select", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 
}


export default new GrupoUnidadMedidaServices();