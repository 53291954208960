//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class UnidadMedidaServices {
     Consultar(
        datos = {
            Id: -1,
            Nombre: '',
            Abreviatura: '',
            Multiplicador:  -1,
            IndActivo: null,
            IdGrupoUnidadMedida: -1
        }    
     ) {
        return http.get("unidadmedida/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 

     ConsultarSelect(
        datos = {
            Id: -1,
            Nombre: '',
            Abreviatura: '',
            Multiplicador:  -1,
            IndActivo: null,
            IdGrupoUnidadMedida: -1
        }
    ) {
        return http.get("unidadmedida/consultar/select", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }  

     Actualizar(
        idUsuario,
        id,
        nombre,
        abreviatura,
        multiplicador,
        indActivo,
        idGrupoUnidadMedida
    ) {   
        const datos = {
            IdUsuario: idUsuario,
            Data: {
              Id: id,
              Abreviatura: abreviatura, 
              Multiplicador: multiplicador,
              IndActivo: indActivo,
              IdGrupoUnidadMedida: idGrupoUnidadMedida,
              Nombre:nombre
            }
          }
          console.log(datos) 
        return http.post("/unidadmedida/Actualizar",  datos )
          .catch((error) => {
            if (error.response.status == 401) {
              store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
              router.push("/pages/login");
            }
          });
      }
}


export default new UnidadMedidaServices();