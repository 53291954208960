//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class ColorServices {
     Consultar(
        datos = {
            Id: -1,
            Descripcion: '',
            indActivo:null
        }
    ) {
        return http.get("color/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }  

    ConsultarSelect(
        datos = {
            Id: -1,
            Descripcion: '',
            indActivo:null
        }
    ) {
        return http.get("color/consultar/select", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }  
    
    Actualizar(
        idUsuario,
        Id ,
        Descripcion,
        indActivo
    ) {   
        const datos = {
            IdUsuario: idUsuario,
            Data: {
              Id: Id,
              Descripcion: Descripcion, 
              IndActivo: indActivo
            }
          }
          console.log(datos) 
        return http.post("/color/actualizar",  datos )
          .catch((error) => {
            if (error.response.status == 401) {
              store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
              router.push("/pages/login");
            }
          });
      }
}


export default new ColorServices();