<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="900" scrollable>
      <Lista ref="ListaRef" :_multiSelected="_multiSelected" :_selected="_selected" @sendImgSelect="sendImgSelect" />
    </v-dialog>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import Lista from './Lista.vue'
import Upload from './Upload.vue'
export default {
  components: {
    Lista,
    Upload,
  },
  props: {
    BntVisible: Boolean,
    _multiSelected: Boolean,
    _selected: Boolean,
  },
  setup(props, context) {
    const ListaRef  = ref(null)
    const show = ref(false)
    const UploadImg = ref(null)
    const ListaImg = ref(null)
    const dialog = ref(false)
    const Abrir = (items) => {
      dialog.value = true
      ListaRef.value.selected =items
    }
    const UploadImages = () => {
      show.value = true
    }
    const imgSelect = ref([])
    const sendImgSelect = items => {
      context.emit('sendImgSelect', items)
    }
    return {
      Abrir,
      UploadImages,
      sendImgSelect,
      imgSelect,
      dialog,
      ListaImg,
      show,
      UploadImg,
      ListaRef
    }
  },
}
</script>