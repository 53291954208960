<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-subtitle>
            UnidadMedida
        </v-card-subtitle>
        <v-form ref="form" lazy-validation>
            <v-card-text class="d-flex">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" lg="6">
                                <SelectGrupoUnidadMedida ref="SelectGrupoUnidadMedidaRef" :rules="reglas.GrupoUnidadMedida" :clearable="true" :dense="true"
                                    :outlined="true" :icon="false" v-model="datos.idGrupoUnidadMedida">
                                </SelectGrupoUnidadMedida>

                                <v-text-field :rules="reglas.nombre" v-model="datos.nombre"
                                    label="Nombre *" required outlined dense hide-details="auto"
                                    class="mb-6 mt-5"></v-text-field>
                                    <v-text-field :rules="reglas.abreviatura" v-model="datos.abreviatura"
                                    label="Abreviatura *" required outlined dense hide-details="auto"
                                    class="mb-6 mt-5"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-text-field :rules="reglas.multiplicador" v-model="datos.multiplicador"
                                    label="Multiplicador *" required outlined dense hide-details="auto"
                                    class="mb-6  "></v-text-field>
                                <v-switch v-model="datos.indActivo" :label="`Ind Activo`"></v-switch>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-btn color="secondary" class="me-3  " @click="limpiar()" small> Limpiar
                        </v-btn>
                        <v-btn color="primary" class="me-3  " small @click="Guardar()"> Guardar </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import SelectGrupoUnidadMedida from '../../grupo-unidad-medida/componentes/Select.vue'
import UnidadMedidaServices from '@/api/servicios/UnidadMedidaServices'
export default {
    components: {
        SelectGrupoUnidadMedida
    },
    setup(props, context) {

        const guardando = ref(false)
        const form = ref(null)
        
        const SelectGrupoUnidadMedidaRef = ref(null)
        const reglas = {
            nombre: [

                v => !!v || "Descripcion es requerido"
            ],
            abreviatura: [

                v => !!v || "abreviatura es requerido"
            ],
            multiplicador: [

            v => !!v || "multiplicador es requerido"
            ],
            GrupoUnidadMedida: [

                v => !!v || "GrupoUnidadMedida es requerido"
            ]
        }
        const datos = ref({
            "id": -1,
            "nombre": "",
            "indActivo": true,
            "idGrupoUnidadMedida": null,
            "abreviatura": "",
            "multiplicador": 1
        })


        const limpiar = () => {
            datos.value = {
                "id": -1,
                "nombre": "",
                "indActivo": true,
                "idGrupoUnidadMedida": null,
                "abreviatura": "",
                "multiplicador": -1
            };
            form.value.resetValidation();
        }

        const cargandos = ref(false)
        const idGrupoUnidadMedida = (item) => {
            datos.value.idGrupoUnidadMedida = item.id 
        }

        const CargarEditar = (item) => {
            datos.value = item
            SelectGrupoUnidadMedidaRef.value.selectId(item.idGrupoUnidadMedida)  
            goTo(0)
        }
        const validarForm = () => {
            let val = form.value?.validate()

            return val
        }
        const ActualizarLista = item => {
            console.log(item)
            context.emit('GetActualizarLista', { ...item })

        }
        const Guardar = () => {
            guardando.value = true
            if (validarForm()) {
                try { 
                    UnidadMedidaServices.Actualizar(
                        store.state.user.idUsuario,
                        datos.value.id,
                        datos.value.nombre,
                        datos.value.abreviatura,
                        datos.value.multiplicador,
                        datos.value.indActivo,
                        datos.value.idGrupoUnidadMedida
                    ).then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'success',
                            })
                            ActualizarLista(response.data.datos.datos)
                            limpiar()
                        } else {

                            store.commit('setAlert', {
                                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                                type: 'warning',
                            })
                        }
                    })
                        .catch(error => {
                            console.log(error)
                            store.commit('setAlert', {
                                message: error,
                                type: 'error',
                            })
                        })
                        .finally(() => {
                            guardando.value = false
                        })
                } catch (error) {
                    //alert(error)
                    store.commit('setAlert', {
                        message: error,
                        type: 'error',
                    })
                }
            } else {
                store.commit('setAlert', {
                    message: 'Verifique que todos los datos estén completos',
                    type: 'warning',
                })
            }

        }


        return {
            reglas,
            datos,
            CargarEditar,
            cargandos,
            limpiar,
            Guardar,
            form,
            ActualizarLista,
            idGrupoUnidadMedida,
            SelectGrupoUnidadMedidaRef
        }
    }
}

</script>