<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Marca </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  :rules="reglas.descripcion"
                  v-model="datos.descripcion"
                  label="Descripcion *"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-6 mt-5"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-switch v-model="datos.indActivo" :label="`Ind Activo`"></v-switch>
              </v-col>
              <v-col cols="12" lg="6">
                <AddImages
                  :_width="250"
                  :_height="200"
                  ref="AddImagesRef"
                  @sendImg="sendImg"
                  :_selected="true"
                  title="Imagen"
                />
                <small class="error--text">* Es requerido</small>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import MarcaServices from '@/api/servicios/MarcaServices'

import AddImages from '@/views/sistema/configuracion/imagen/componentes/AddImages.vue'
export default {
  components: {
    AddImages,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const reglas = {
      descripcion: [v => !!v || 'Descripcion es requerido'],
    }
    const AddImagesRef = ref(null)

    const datos = ref({
      id: -1,
      descripcion: '',
      indActivo: true,
      image: null,
    })
    const sendImg = item => {
      datos.value.image = item.img[0]
    }
    const limpiar = () => {
      datos.value = {
        id: -1,
        descripcion: '',
        indActivo: true,
        image: null,
      }
      form.value.resetValidation()
      AddImagesRef.value.imagenes = []
    }

    const cargandos = ref(false)

    const CargarEditar = item => {
      datos.value = item
      if (item.image){
        AddImagesRef.value.imagenes = [item.image]
      }else{
        AddImagesRef.value.imagenes = []
      }
     
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }
    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          MarcaServices.Actualizar(
            store.state.user.idUsuario,
            datos.value.id,
            datos.value.descripcion,
            datos.value.indActivo,
            datos.value.image,
          )
            .then(response => {
              console.log(response)
              if (response.data.estatus == true) {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          //alert(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      AddImagesRef,
      sendImg,
    }
  },
}
</script>